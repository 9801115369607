.custom-pagination .ant-pagination-item {
    color: #ffffff !important; /* Change text color */
    border-color: #2b2c63 !important; /* Change border color */
  }
  
  .custom-pagination .ant-pagination-item a {
    color: #ffffff !important; /* Change page number color */
  }
  
  .custom-pagination .ant-pagination-item-active {
    color: #5259e3 !important; /* Change active page text color */
    border-color: #2b2c63 !important;
  }
  
  .custom-pagination .ant-pagination-item-active a {
    color: #5259e3 !important; /* Change active page text color */
  }
  