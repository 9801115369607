a {
    text-decoration: none;
}

.react-tooltip {
    max-width: 180px;
    background: #232631;
    border-radius: 5px;
    border: 1px solid #454A55;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 100;
}

.react-tooltip-arrow {
    height: 6px;
    width: 6px;
}

.antd_font_14 input {
    font-size: 14px!important;
}

.antd_input_font_12 input {
    font-size: 12px!important;
}

.antd_token_select .ant-select-selection-item {
    margin-top: 2px;
}

.antd_input_small .ant-input-number-input-wrap {
    font-size: 12px!important;
}

.antd_input_small input {
    padding: 1px 5px 1px 5px!important;
}

/* Don't set display:none: in this case antd will not show datepicker content */
.antd_daterange_container .ant-picker-range {
    width: 0;
    visibility: hidden;
    height: 0;
    border: none;
    padding: 0;
}

.antd_modal_container {
    .ant-modal-content {
        padding: 0;
    }
}

.antd_dropdown_overlay ul, .antd_dropdown_overlay ul li {
    padding: 0!important;
}

.rho_slider_input input, input.rho_slider_input {
    /*text-align: left!important;*/
    /*padding-right: 22px!important;*/
    font-size: 18px!important;
    padding-left: 0!important;
}
